import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";

import PrivateChat from "../../Assets/Projects/PrivateChat.gif";
import Portfolio from "../../Assets/Projects/Portfolio.png";
import Ecart from "../../Assets/Projects/ecart.png";
import ExpenseTracker from "../../Assets/Projects/ExpenseTracker.png";

import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="Fluorescent-Blue">Works </strong>
        </h1>
        <p>Here are a few projects I've worked on recently.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>



          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={PrivateChat}
              title="Discord Multiporpuse Open Source Bot"
              description="A Personal discord bot that you can config and that can be your first bot!! easy to config and have all this option : verify, welcome , avatar command, staff test and a lot more!"
              ghLink="https://github.com/MishaelW3/Full-Discord-Bot-V1"
            />
          </Col>

          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={ExpenseTracker}
              title="Fivem Bot Master"
              description="An Discord Fivem Multiporpuse Bot That Help You To keep up your rp server in the sky, it show real time players on the server, top 10 leaderboard, ticket system , verify , welcome , staff application, and a lot more!  "
              ghLink="https://github.com/MishaelW3/Expense-Tracker"
              demoLink="https://fivem-botmaster.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default Projects;
